import { CAVD_PUBS_COLS, CAVD_PUBS_SORTS, CAVD_STUDY_COLS, CAVD_STUDY_SORTS, GHDC_PUBS_SORTS, GHDC_PUBS_COLS, CAVD_CLINICAL_SORTS, CAVD_CLINICAL_COLS  } from "./SharepointConsts";

const CAVDPublications = 'CAVD Publications';
const CAVDStudyResults= 'CAVD Study Results';
const GHDCPublications= 'GHDC Publications';
const CAVDClinicalTrials= 'CAVD Clinical Trials';
export class SharepointService {
    constructor(baseAPI, datasource){
        this.datasource=datasource;
        this.baseAPI = baseAPI;
    }
    async getDataFromDataSource(number){
        if(this.datasource === CAVDPublications){
            return await this.getCAVDPublications(number)
        } else if (this.datasource === CAVDStudyResults){
            return await this.getCAVDStudyResults(number)
        }
        else if (this.datasource === GHDCPublications){
            return await this.getGHDCPublications(number)
        }
        else if (this.datasource === CAVDClinicalTrials){
            return await this.getCAVDClinicalTrials(number)
        }
        if(this.datasource === 'CAVD Institutions'){
            return await this.getCAVDInstitutions(number)
        }
        if(this.datasource === 'GHDC Institutions'){
            return await this.getGHDCInstitutions(number)
        }
        if(this.datasource === 'CTVD Jobs and Opportunities'){
            return await this.getCTVDJobsNOpp(number)
        }
    }
    getSortValues(){
        if(this.datasource === CAVDPublications){
            return CAVD_PUBS_SORTS
        } else if (this.datasource === CAVDStudyResults){
            return CAVD_STUDY_SORTS;
        }
         else if (this.datasource === GHDCPublications){
            return GHDC_PUBS_SORTS;
        }
        else if (this.datasource === CAVDClinicalTrials){
            return CAVD_CLINICAL_SORTS;
        }
    }
    getTableColumns(){
        if(this.datasource === CAVDPublications){
            return CAVD_PUBS_COLS
        } else if (this.datasource === CAVDStudyResults){
            return CAVD_STUDY_COLS;
        }
         else if (this.datasource === GHDCPublications){
            return GHDC_PUBS_COLS;
        }
        else if (this.datasource === CAVDClinicalTrials){
            return CAVD_CLINICAL_COLS;
        }
    }
    getDefaultSort(){
        let defaultSort = null;
        if(this.datasource===CAVDPublications){
            defaultSort = [
                {
                    "columnname" : "PublishDate",
                    "order" : "desc"
                },
            ]
        } else if(this.datasource===CAVDStudyResults){
            defaultSort = [
                {
                    "columnname" : "ReleasedToPublic",
                    "order" : "desc"
                },
            ]
        }
        else if(this.datasource=== GHDCPublications){
            defaultSort = [
                {
                    "columnname" : "PublicationYear",
                    "order" : "desc"
                },
            ]
        }
        else if(this.datasource=== CAVDClinicalTrials){
            defaultSort = [
                {
                    "columnname" : "TrialID",
                    "order" : "asc"
                },
            ]
        }
        return defaultSort;
    }
    generateBody(options){
        let newBody = {
            orderby: this.getDefaultSort(),
            top: "30",
            skiptoken: ""
        }
        if (options.sort && options.sort.length > 0){
            newBody.orderby = options.sort
        }
        if(options.numberOfResults) {
            newBody.top = options.numberOfResults.toString()
        }
        if (options.skipToken){
            newBody.skiptoken = options.skipToken
        }
        if(options.search){
            newBody.search = options.search
            newBody.from = options.from.toString()
        }
        return newBody
    }
    async getCAVDPublications(options){
        return await this.fetchData('getpublication', options);
    }
    async getCAVDStudyResults(options){
        return await this.fetchData('getcsfassays', options);
    }
    async getGHDCPublications(options){
        return await this.fetchData('getghdcpublication', options);
    }
    async getCAVDClinicalTrials(options){
        return await this.fetchData('getcavdclinicaltrials', options);
    }
    async fetchData(source, options){
        let body = this.generateBody(options)
        try {
            const results = await (await fetch(this.baseAPI + source,{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })).json();
            return results;
        } catch (err){
            console.log(err)
            return {data:[]}
        }
    }
    async getCAVDInstitutions(){
        try {
            const results = await (await fetch(this.baseAPI+'getinstitution', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })).json();
            return results;
        } catch (err){
            console.log(err);
            return {data:[]}
        }
    }
    async getGHDCInstitutions(){
        try {
            const results = await (await fetch(this.baseAPI+'getghdcinstitution', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })).json();
            return results;
        } catch (err){
            console.log(err);
            return {data:[]}
        }
    }
    async getCTVDJobsNOpp(){
        try {
            const results = await (await fetch(this.baseAPI+'getctvdjobopportunity', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })).json();
            return results;
        } catch (err){
            console.log(err);
            return {data:[]}
        }
    }
}
